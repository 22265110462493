@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: "Agency-Bold";
    src: url("https://www.nba.com/resources/static/team/v2/clippers/fonts/Agency_FB/Agency_FB_Bold.otf") format("truetype");
}

body {
    padding-top: 111px;
}
.fullscreen-bg {
    position: relative;
    background: #000;
}

.fullscreen-bg__video {
    position: relative;
    right: 0;
    top: 0;
    width: 100%;
}

.videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
  }
  .videoWrapper video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

.navigation {
    background: black;
    color: white;
}

.nav-font {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 18px;
}

.nav-logo {
    height: 125px;
}

#story-header {
    width: 100%
}
.semi-font {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
}

.sm-semi-font {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 36px; 
}

.button-text {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 16px;
}

.header-background {
    background-image: url('https://www.nba.com/resources/static/team/v2/clippers/img/1200-CE-FPO-background-thumb-201115.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 120px 60px;
    height: 1000px;
    color: white;
}

.inner-header {
    background-image: url('https://www.nba.com/resources/static/team/v2/clippers/img/1548x888-CE-hero-drk-201128.jpg');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover !important;
}

.xl-header {
    font-family: 'Agency-Bold', sans-serif;
    font-size: 100px;
    line-height: 100px;
}

.lg-header {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 48px;
    line-height: 90px;
}

.title-text {
    font-size: 64px;
    line-height: 72px;
    font-family: 'Agency-Bold', sans-serif;
}

.step-text {
    font-size: 40px;
    line-height: 48px;
    font-family: 'Agency-Bold', sans-serif;
}

.presale-background  {
    background-image: url('https://www.nba.com/resources/static/team/v2/clippers/img/1920x720-CE-presale-background-201118.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: cover cover;
    padding: 120px 60px;
    color: white;
}

.story-background {
    background-image: url('https://www.nba.com/resources/static/team/v2/clippers/img/1920-CE-story-bg-201118@2x.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    color: white;
    padding: 60px 60px 120px 60px;
}

.inner-story {
    background: black;
}

.story-text {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 46px;
}

.carousel-background {
    background-image: url('https://www.nba.com/resources/static/team/v2/clippers/img/844x1080-ce-carousel-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
}

.slides {
    position: relative;
  }
  .slides .slick-prev, .slides .slick-next {
    position: absolute;
    top: 50%;
    z-index: 1;
  }
  
  .slick-prev {
    left: 5px;
  }
  
  .slick-next {
    right: 5px;
  }

  .carousel-left {
    width: 50px;
    color: black;
    position: absolute;
    left: 15px;
    bottom: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    border: 4px solid white;
    padding: 10px;
    border-radius: 50%;
    height: 50px;
    background: white;
}

.carousel-right {
    padding: 10px;
    z-index: 1;
    width: 50px;
    height: 50px;
    color: black;
    position: absolute;
    right: 15px;
    bottom: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 4px solid white;
    border-radius: 50%;
    background: white;
}

.deposit-background {
    background-image: url('https://www.nba.com/resources/static/team/v2/clippers/img/light-halftone-texture-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 120px 60px;
    color: white;
}

.workshop-background {
    background: black;
    padding: 120px 60px;
    color: white;
}

.royyaldog-background {
    background-image: url('https://www.nba.com/resources/static/team/v2/clippers/img/1920-spray-can-bg-201118@2x.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 120px 60px;
}

.royyaldog-inner {
    background-image: url('https://www.nba.com/resources/static/team/v2/clippers/img/light-halftone-texture-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.makeyourmark-background {
    background-image: url('https://www.nba.com/resources/static/team/v2/clippers/img/1770x1080-ce-challenge-bg-borders.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 120px 60px;
    color: white;
}

.merch-pad {
    padding: 30px 60px;
}

.merch-img {
    height: 215px;
}

.ce-button {
    border: 4px solid white;
    color: white;
    background: transparent;
    padding: 10px 30px; 
}

.img-fluid {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}

.desktop-pad {
    padding: 120px 60px;
}

.card-pad {
    padding: 120px 40px 0px 80px;
}

a {
    color: white!important;
}

a:hover {
    color: white!important;
    text-decoration: none!important;
}

a:visted {
    color: white!important;
    text-decoration: none!important;
}
/* Media Queries */
@media only screen and (min-width: 1600px) {
    .merch-pad {
        padding: 50px 100px;
    }
    .merch-img {
        height: 250px;
    }
}
@media only screen and (max-width: 991px) {
    body {
        padding-top: 138px;
    }

    .nav-font {
        font-size: 12px;
    }

    .nav-logo {
        height: 125px;
    }

    .header-background {
        background-image: url('https://www.nba.com/resources/static/team/v2/clippers/img/933x1809-CE-hero-mobile-201128.jpg');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        padding: 0px;
        color: white;
        height: 700px;

    }

    .xl-header {
        font-size: 54px;
        line-height: 58px;
    }

    .lg-header {
        font-size: 30px;
        line-height: 43px;
    }

    .story-background {
        background: black;
    }

    .story-text {
        font-size: 18px;
        line-height: 32px;
    }

    .title-text {
        font-size: 36px;
        line-height: 42px;
    }
    .step-text {
        font-size: 24px;
        line-height: 24px;
    }
}

@media only screen and (max-width: 768px) {
    body {
        padding-top: 80px;
    }
    .header-background {
        padding: 24px 24px;
    }
}

@media only screen and (max-width: 766px) {
    body {
        padding-top: 25px;
    }

    .desktop-pad {
        padding: 24px 24px;
    }
    .card-pad {
        padding: 24px 24px; 
    }
    .nav-logo {
        height: 75px;
    }
    .desktop-pad {
        padding: 24px 24px;
    }
    .header-background {
        padding: 24px 24px;
    }
    .presale-background  {
        padding: 24px 24px;
    }
    .story-background {
        padding: 24px 24px;
    }
    .workshop-background {
        padding: 24px 24px;
    }
    .deposit-background {
        padding: 24px 24px;
    }
    .royyaldog-background {
        padding: 24px 24px;
    }
    .makeyourmark-background {
        padding: 90px 24px;
    }
    .semi-font {
        font-size: 18px;
        line-height: 32px;
    }
    .sm-semi-font {
        line-height: 32px; 
    }
    .slick-dots li button:before {
        color: red;
        opacity: .50;
    }
    .slick-dots li.slick-active button:before {
        color: red;
        opacity: 100;
    }
    .merch-pad {
        padding: 24px;
    }
}
